export default [
  {
    title: 'My Workspace',
    route: 'workspace',
    icon: 'LayersIcon',
    roles:['admin','Staff', 'SiteAdmin', 'CompanyAdmin']
  },
  {
    title: 'Cameras',
    route: 'cameras',
    icon: 'VideoIcon',
    roles:['admin','Staff', 'SiteAdmin', 'CompanyAdmin']
  },
  {
    title: 'Rules',
    route: 'rules',
    icon: 'SlidersIcon',
    roles:['admin','Staff', 'SiteAdmin', 'CompanyAdmin']
  },
  // {
  //   title: 'My Workflows',
  //   route: 'workflows',
  //   icon: 'GitPullRequestIcon',
  //   roles:['admin','Staff', 'SiteAdmin', 'CompanyAdmin']
  // },

  {
    title: 'Events',
    route: 'violations',
    icon: 'ZapIcon',
    roles:['admin','Staff', 'SiteAdmin', 'CompanyAdmin']
  },
  {
    title: 'Insights',
    route: 'insights',
    id: 'Insights',
    icon: 'BarChart2Icon',
    roles:['admin','Staff', 'SiteAdmin', 'CompanyAdmin']

  },
  {
    title: 'Reporting',
    route: 'reporting',
    icon: 'FileIcon',
    roles:['admin','Staff', 'SiteAdmin', 'CompanyAdmin']
  },
  {
    title: 'Users',
    route: 'admin-users-list',
    icon: 'UserIcon',
    roles:['admin','Staff', 'SiteAdmin', 'CompanyAdmin']

  },
  // {
  //   title: 'Hub',
  //   route: 'hub',
  //   icon: 'MessageSquareIcon',
  //   roles:['admin','Staff', 'SiteAdmin', 'CompanyAdmin']
  // },
  {
    title: 'Audit Log',
    route: 'audit-manager',
    icon: 'ArchiveIcon',
    roles:['admin','Staff', 'SiteAdmin', 'CompanyAdmin']
  },
  {
    title: 'Protex Admin',
    route: 'superuser',
    icon: 'UserPlusIcon',
    roles: ['SuperUser']
  },
  {
    title: 'Create Company',
    route: 'createcompany',
    icon: 'KeyIcon',
    roles: ['SuperUser']
  },
  {
    title: 'Handle Site Assignment',
    route: 'siteassignment',
    icon: 'FileIcon',
    roles: ['SuperUser']
  }
]

<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <div
      class="
        bookmark-wrapper
        align-items-center
        flex-grow-1
        d-lg-flex
        header-navbar
        navbar-nav
      "
      style="margin-left: 5px; min-height: auto !important; background:transparent; !important"
    > 
    <img v-if="companyLogo" :src="companyLogo" height="40px" style="margin-right:10px" />
    <feather-icon v-if="!companyLogo" class="text-body" icon="GlobeIcon" size="16" />
      <p v-if="!companyLogo"
        class="font-weight-bolder mb-0"
        style="margin-left: 8px; margin-right: 10px"
      >
        {{ companyName }}
      </p>

      

      <div
        style="border-left: 2px solid #f2f5f7; height: 40px; margin-right: 10px"
      ></div>

      <feather-icon class="text-body" icon="MapPinIcon" size="16" />
      <b-dropdown
        class="site-dropdown"
        :text="siteLoaded || 'Loading'"
        variant="flat-dark"
        style="margin-left: 0px; margin-top: 1px; background-color: transparent"
      >
        <b-dropdown-item
          v-for="site in sites"
          :key="site.site.siteName"
          @click="reloginUser(site)"
        >
          <p class="font-weight-bolder mb-0">
            {{ site.site.site_name }}
          </p>
        </b-dropdown-item>
        <div
          v-if="!sites.length"
          style="
            width: 200px;
            margin-left: 16px;
            padding-top: 5px;
            padding-bottom: 5px;
          "
        >
          No other sites available.
        </div>
      </b-dropdown>
    </div>

    <!-- Right Col -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <locale />
      <notification-dropdown :notifications="notifications" />
      <user-dropdown />
    </b-navbar-nav>

    <b-modal
      id="password-modal"
      centered
      size="sm"
      hide-footer
      title="Unsaved Changes!"
    >
      Please enter your password

      <center>
        <b-form>
          <b-row>
            <b-col cols="12" md="4">
              <b-form-group label="password" label-for="password">
                <b-form-input id="password" v-model="userPassword" />
              </b-form-group>
            </b-col>
          </b-row>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            @click="testfunc()"
          >
            <feather-icon icon="SaveIcon" />
            Submit
          </b-button>
        </b-form>
      </center>
    </b-modal>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BButtonGroup,
} from "bootstrap-vue";

import NotificationDropdown from "./components/NotificationDropdown.vue";
import UserDropdown from "./components/UserDropdown.vue";
import Locale from "./components/Locale.vue";
import NOTIFY from "@/graphql/notification/subscription/SubscribeNotify.gql";
import GETUSERSITES from "@/graphql/dashboard/query/GetUserSites.gql";
import GETUSERCURRENTSITE from "@/graphql/dashboard/query/GetUserCurrentSite.gql";
import EventBus from "@/event-bus.js";
import useJwt from "@/auth/jwt/useJwt";
import axios from "axios";
let ENDPOINT = process.env.VUE_APP_LAMBDA_CHANGE_USER_SITES;
const COMPANY_LOGO_API_ENDPOINT = process.env.VUE_APP_LAMBDA_PRESIGNED_PROFILE_IMG;
import { Auth } from "aws-amplify";
import GET_USER_INFO from "@/graphql/login/query/GetUserData.gql";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  getUserRole,
  getUserSiteId,
  getUserId,
  getUserName,
  getUserProfileImage,
  getUserEmail,
} from "@/auth/utils";

export default {
  components: {
    BLink,
    BButton,
    BButtonGroup,
    BForm,
    BFormGroup,
    BFormInput,
    // Navbar Components
    BNavbarNav,
    NotificationDropdown,
    UserDropdown,
    Locale,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
  },
  data() {
    return {
      userPassword: "",
      notifications: [],
      currentSite: {},
      site: "",
      sites: [],
      companyName: "",
      siteLoaded: "",
      companyLogo:''
    };
  },
  created() {
    this.$apollo
      .query({
        query: GETUSERCURRENTSITE,
        variables: {
          user_id: getUserId(),
        },
      })
      .then(({ data }) => {
        this.siteLoaded = data.users[0].site.site_name;
        this.currentSite = {
          id: data.users[0].site.id,
          site: data.users[0].site,
        };

        this.$apollo
          .query({
            query: GETUSERSITES,
            variables: {
              user_id: parseInt(getUserId()),
            },
          })
          .then(({ data }) => {
            this.sites = data.sites;
            this.sites = this.sites.filter((site) => {
              return site.site.site_name != this.currentSite.site.site_name;
            });
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  },
  mounted() {
    this.companyName = JSON.parse(localStorage.getItem("userData")).company;
    let companyImg = JSON.parse(localStorage.getItem("userData")).companyLogo;
    if(companyImg){
      this.downloadImage(companyImg)
    }
  },
  apollo: {
    $subscribe: {
      notifications: {
        query: NOTIFY,
        variables: {
          // site_id: Number(localStorage.getItem('SiteId')),
          recepient_id: localStorage.getItem("userId"),
        },
        result({ data }) {
          console.log("Got notification");
          this.notifications = data.notifications;
          EventBus.$emit("notify", data.notifications);
        },
      },
    },
  },

  methods: {
    async downloadImage(fileName) {
      console.log('Download clicked')
      const token = await localStorage.getItem("JoT");
      // Get the presigned URL
      const response = await axios({
        method: 'GET',
        url: COMPANY_LOGO_API_ENDPOINT,
        headers: {
          Authorization: token.replace(/['"]+/g, ""),
        },
        params: {
          fileName: fileName,
          type:"download",
          Authorization: token.replace(/['"]+/g, ''),
        }
      })
      console.log('Response: ', response.data)
      console.log('Downloading: ', fileName)
      const result = await fetch(response.data.url, {
        method: 'GET',
      }).then(response => response.blob())
        .then(blob => {
            this.companyLogo = URL.createObjectURL(blob)
            console.log("File Downloaded Successfully!")
        })
    },
    async reloginUser(site) {
      try{
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const currentSession = await Auth.currentSession();
        if(cognitoUser && currentSession){
          EventBus.$emit("start-site-switch", site);
          this.currentSite = site;
          const token = await localStorage.getItem("JoT");
          var response = await axios({
            method: "POST",
            url: ENDPOINT,
            data: {
              site_id: this.currentSite.id,
              Authorization: token.replace(/['"]+/g, ""),
            },
            headers: {
              Authorization: token.replace(/['"]+/g, ""),
            },
          });
          cognitoUser.refreshSession(
            currentSession.refreshToken,
            (err, session) => {
              console.log("session", err, session);
              var { idToken, refreshToken, accessToken } = session;
              console.log("id token", idToken);
              localStorage.setItem("refreshToken", JSON.stringify(refreshToken));
              localStorage.setItem("JoT", JSON.stringify(idToken.jwtToken));
              const userData = {
                fullName: getUserName(),
                username: getUserName(),
                avatar: getUserProfileImage(),
                email: getUserEmail(),
                role: getUserRole(),
                ability: [
                  {
                    action: "manage",
                    subject: "all",
                  },
                ],
                accessToken: accessToken,
                refreshToken: refreshToken,
              };
              localStorage.setItem("userData", JSON.stringify(userData));
              let userInfo = JSON.parse(localStorage.getItem("userData"));
              this.$apollo
              .query({
                query: GET_USER_INFO,
                variables: {
                  email: userInfo.email,
                },
              })
              .then(async (data) => {
                let userData = {
                  ...userInfo,
                  email: data.data.users[0].email,
                  firstName: data.data.users[0].first_name,
                  lastName: data.data.users[0].last_name,
                  jobTitle: data.data.users[0].job_title,
                  profilePicUrl: data.data.users[0].profile_pic_url,
                  companyLogo: data.data.users[0].site.company.logo_uuid,
                  company: data.data.users[0].site.company.company_name,
                };
                localStorage.setItem("userData", JSON.stringify(userData));
                localStorage.setItem("userId", getUserId());
                localStorage.setItem("SiteId", getUserSiteId());
                this.$router.go();
                setTimeout(() => {
                  EventBus.$emit("end-site-switch");
                }, 2000);
              }).catch((err) => {
                this.$router.go();
                setTimeout(() => {
                  EventBus.$emit("end-site-switch");
                }, 2000);
                localStorage.setItem("userId", getUserId());
                localStorage.setItem("SiteId", getUserSiteId());
              });
            }
          );
        }else{
          this.$toast.error({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              text: 'Error in switching sites. Login and try again',
            },
          })
          return
        }
      }catch(e){
        this.$toast.error({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            text: 'Error in switching sites. Login and try again',
          },
        })
      }
    },
  },

  watch: {
    notifications: function (params) {
      console.log("watch", params);
    },
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" >
.site-dropdown {
  color: #29335c !important;
  background-color: transparent;
}

.site-dropdown button {
  color: #052d41 !important;
  font-size: 14px;
  font-weight: bold;
  padding: 0px;
  padding-left: 6px;
}

.site-dropdown button:hover {
  background-color: transparent !important;
}
.site-dropdown button:active {
  background-color: transparent !important;
}
.site-dropdown button:visited {
  background-color: transparent !important;
}
.site-dropdown button:focus {
  background-color: transparent !important;
}

.site-dropdown button {
  font-weight: 600;
}
</style>
